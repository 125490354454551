<template>
  <table class="mt-2 w-full border-collapse">
    <tr v-for="(row, index) in rows" :key="index" class="break-inside-avoid">
      <td class="px-5">
        <table
          class="w-full border-collapse"
          :style="`width:${getRowWidth(row)}%`"
        >
          <tr>
            <td
              v-for="card in row"
              :key="card.id"
              class="px-4 pb-4 align-top"
              :style="`width:${getWidth(card)}%`"
            >
              <CardPrimary :card="card" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import type { Props } from '@autobid/ui/types/components/CardColumnsGroup'
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import CardPrimary from '@autobid/ui/components/elements/card/CardPrimary.vue'

const props = defineProps<Props>()

const WIDTHS = {
  'w-full': 100,
  'w-1/2': 50,
  'w-1/3': 33.3,
  'w-2/3': 66.6,
  'w-1/6': 16.6
}

const getWidth = (card: CardType) => {
  return WIDTHS[card.width?.name ?? 'w-full'] ?? 100
}

const getRowWidth = (row: CardType[]) => {
  const summary = row.reduce((acc, card) => {
    const width = getWidth(card)
    acc += width

    return acc
  }, 0)

  return Math.ceil(summary)
}

const rows = computed(() => {
  let rowSize = 0

  return props.data.cards.reduce((acc, card) => {
    const width = getWidth(card)
    rowSize += width

    if (rowSize > 100 || !acc.length) {
      rowSize = width
      acc.push([])
    }

    acc[acc.length - 1].push(card)
    return acc
  }, [] as CardType[][])
})
</script>
